define("ember-composable-helpers/helpers/has-previous", ["exports", "@ember/component/helper", "@ember/utils", "ember-composable-helpers/helpers/previous", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params"], function (_exports, _helper, _utils, _previous, _isEqual, _getValueArrayAndUseDeepEqualFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasPrevious = hasPrevious;

  function hasPrevious(currentValue, array) {
    let useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (!array) {
      array = [];
    }

    let previousValue = (0, _previous.previous)(currentValue, array, useDeepEqual);
    let isNotSameValue = !(0, _isEqual.default)(previousValue, currentValue, useDeepEqual);
    return isNotSameValue && (0, _utils.isPresent)(previousValue);
  }

  var _default = (0, _helper.helper)(function (params) {
    let {
      currentValue,
      array,
      useDeepEqual
    } = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params);
    return hasPrevious(currentValue, array, useDeepEqual);
  });

  _exports.default = _default;
});