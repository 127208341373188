define("ember-svg-jar/inlined/mod_analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 134c0 .55.45 1 1 1h128c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v128zM30 30h20M30 55h45\" fill=\"none\" stroke=\"#000\" stroke-width=\"5\"/><path d=\"M115 55l-30 50-35-20-20 30\" fill=\"none\" stroke=\"#000\" stroke-width=\"5\"/>",
    "attrs": {
      "viewBox": "0 0 140 140",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
  _exports.default = _default;
});