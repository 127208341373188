define("ember-parachute/-private/parachute-meta", ["exports", "@ember/array", "ember-parachute/-private/query-param", "ember-parachute/-private/symbols"], function (_exports, _array, _queryParam, _symbols) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  /**
   * Meta class used by ember-parachute.
   *
   * @export
   * @class ParachuteMeta
   */

  class ParachuteMeta {
    /**
     * Creates an instance of ParachuteMeta.
     *
     * @param {Object} [queryParams={}]
     * @memberof ParachuteMeta
     */
    constructor() {
      let queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.queryParams = keys(queryParams).reduce((qps, key) => {
        qps[key] = new _queryParam.default(key, queryParams[key]);
        return qps;
      }, {});
      this.queryParamsArray = (0, _array.A)(keys(this.queryParams).map(key => {
        return this.queryParams[key];
      }));
      this.qpMapForController = this.queryParamsArray.reduce((qps, _ref) => {
        let {
          key,
          as,
          scope
        } = _ref;
        qps[key] = {
          as,
          scope
        };
        return qps;
      }, {}); // Meta info used by the decorators

      Object.defineProperty(this.qpMapForController, _symbols.PARACHUTE_QPS, {
        value: true
      });
      this.qpMapForRoute = this.queryParamsArray.reduce((qps, _ref2) => {
        let {
          key,
          replace
        } = _ref2;
        qps[key] = {
          replace
        };
        return qps;
      }, {});
      this.defaultValues = this.queryParamsArray.reduce((defaults, _ref3) => {
        let {
          key,
          defaultValue
        } = _ref3;
        defaults[key] = defaultValue;
        return defaults;
      }, {});
    }

  }

  _exports.default = ParachuteMeta;
});