define("ember-froala-editor/helpers/html-safe", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlSafe = htmlSafe;

  function htmlSafe(context) {
    for (var _len = arguments.length, partial = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      partial[_key - 1] = arguments[_key];
    }

    // @on-eventName={{html-safe (fn (mut this.content))}}
    if (typeof context === 'function') {
      return function toStringClosure(content) {
        let contentSafeString = (0, _template.isHTMLSafe)(content) ? content : (0, _template.htmlSafe)(content);

        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        return context(contentSafeString, ...partial, ...args);
      }; // @content={{html-safe this.content}}
    } else {
      return (0, _template.isHTMLSafe)(context) ? context : (0, _template.htmlSafe)(context);
    }
  }

  var _default = (0, _helper.helper)(_ref => {
    let [context, ...partial] = _ref;
    return htmlSafe(context, ...partial);
  });

  _exports.default = _default;
});