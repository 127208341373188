define("ember-froala-editor/helpers/froala-method", ["exports", "@ember/component/helper", "@ember/debug", "@ember/object"], function (_exports, _helper, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.froalaMethod = froalaMethod;

  function froalaMethod(methodName) {
    let helperHash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    for (var _len = arguments.length, helperParams = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      helperParams[_key - 2] = arguments[_key];
    }

    (true && !(typeof methodName === 'string' && methodName.length > 0) && (0, _debug.assert)('{{froala-method}} helper needs a string method path as the first argument', typeof methodName === 'string' && methodName.length > 0)); // Create a closure to pass back as the "action" to be triggered

    return function froalaMethodClosure() {
      for (var _len2 = arguments.length, closureParams = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        closureParams[_key2] = arguments[_key2];
      }

      // Editor might be passed in as the first arg if also wrapped with {{froala-arg}}
      let editor = typeof closureParams[0] === 'object' && closureParams[0].component ? closureParams[0] : this;
      (true && !(typeof editor === 'object' && editor.component) && (0, _debug.assert)('{{froala-method}} helper cannot determine the editor instance', typeof editor === 'object' && editor.component)); // Get the actual method using ember.get() so that nested paths work here

      let method = (0, _object.get)(editor, methodName);
      (true && !(typeof method === 'function') && (0, _debug.assert)('{{froala-method}} helper needs a valid method path as the first argument', typeof method === 'function')); // Copy helperParams to pass into the method

      let methodParams = [...helperParams]; // The helper hash can be used to "replace" the param
      // values with params received from the event...

      for (let propName in helperHash) {
        // Determine the positions within each params arrays
        // which should be used for replacing. parseInt() in
        // case the value is a string integer, ex: "2".
        let helperPos = helperParams.indexOf(propName);
        let eventPos = parseInt(helperHash[propName]); // If the parameter "name" was found in the helperParams,
        // and the relative arguments contains something at that position,
        // then go ahead and replace the param value...

        if (helperPos !== -1 && closureParams.length >= eventPos) {
          methodParams[helperPos] = closureParams[eventPos - 1];
        }
      } // Trigger the Froala Editor method


      return method(...methodParams);
    };
  }

  var _default = (0, _helper.helper)((_ref, hash) => {
    let [methodName, ...partial] = _ref;
    return froalaMethod(methodName, hash, ...partial);
  });

  _exports.default = _default;
});