define("ember-inputmask/components/one-way-zip-code-mask", ["exports", "ember-inputmask/components/one-way-input-mask", "@ember/object"], function (_exports, _oneWayInputMask, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class OneWayZipCodeMask
   */
  var _default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('fullCode'),

    /**
     * Allows users to optionally enter the full ZIP+4 area code.
     *
     * @argument fullCode
     * @type Boolean
     */
    fullCode: false,

    /**
     * @computed mask
     * @override
     */
    mask: (0, _object.computed)('fullCode', function () {
      if ((0, _object.get)(this, 'fullCode')) {
        return '99999[-9999]';
      }

      return '99999';
    })
  });

  _exports.default = _default;
});