define("ember-froala-editor/helpers/froala-html", ["exports", "@ember/component/helper", "@ember/debug", "@ember/template"], function (_exports, _helper, _debug, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.froalaHtml = froalaHtml;

  function froalaHtml(callback) {
    let returnSafeString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    for (var _len = arguments.length, partial = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      partial[_key - 2] = arguments[_key];
    }

    (true && !(typeof callback === 'function') && (0, _debug.assert)('{{froala-html}} helper requires a function as the first parameter', typeof callback === 'function'));
    return function froalaHtmlClosure() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      // Editor might be passed in as the first arg if also wrapped with {{froala-arg}}
      // Note: Shift editor off args so it isn't "double passed" when args are spread on the callback
      let editor = typeof args[0] === 'object' && args[0].component ? args.shift() : this;
      (true && !(editor.html && typeof editor.html.get === 'function') && (0, _debug.assert)('{{froala-html}} helper cannot determine the editor instance', editor.html && typeof editor.html.get === 'function')); // Get the html content from the editor, making it a SafeString if needed

      let html = returnSafeString ? (0, _template.htmlSafe)(editor.html.get()) : editor.html.get(); // Call the setter, passing in the html first then all others

      return callback(html, editor, ...partial, ...args);
    };
  }

  var _default = (0, _helper.helper)((_ref, _ref2) => {
    let [callback, ...partial] = _ref;
    let {
      returnSafeString = true
    } = _ref2;
    return froalaHtml(callback, returnSafeString, ...partial);
  });

  _exports.default = _default;
});