define("ember-short-number/-private/hydrate", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.lookupByFactoryType = lookupByFactoryType;

  /* globals requirejs */

  /** @private **/
  function lookupByFactoryType(type, modulePrefix) {
    return Object.keys(requirejs.entries).filter(key => {
      return key.indexOf(`${modulePrefix}/${type}/`) === 0;
    });
  }
  /**
   * Peeks into the requirejs map and registers all locale data (CLDR & Translations) found.
   *
   * @private
   */


  function _default(service, owner) {
    const config = owner.resolveRegistration('config:environment');
    const cldrs = lookupByFactoryType('cldrs-shorts', config.modulePrefix);

    if (!cldrs.length) {
      (true && (0, _debug.warn)('[ember-short-number] project is missing CLDR data', false, {
        id: 'ember-short-number-missing-cldr-data'
      }));
    }

    cldrs.map(moduleName => owner.resolveRegistration(`cldrs-short:${moduleName.split('/').pop()}`)).forEach(data => data.forEach(service.addLocaleData.bind(service)));
  }
});