define("ember-svg-jar/inlined/mod_video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke:#000;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Video-Movies-TV__x2F__Video-Players__x2F__video-player-1\"><g id=\"Group_39\"><g id=\"video-player-1\"><path id=\"Shape_204\" class=\"st0\" d=\"M19.1 4.1c-.1-.4-.4-.7-.7-.7-5.6-.7-11.2-.7-16.7 0-.4 0-.7.3-.8.7C.2 8 .2 11.9.9 15.8c.1.4.4.7.7.7 5.6.7 11.2.7 16.7 0 .4-.1.7-.3.7-.7.8-3.9.8-7.9.1-11.7z\"/><path id=\"Shape_205\" class=\"st0\" d=\"M7.7 6.7c-.1-.1-.3-.1-.4 0-.1.1-.2.2-.2.4v5.7c0 .2.1.3.2.4.1.1.3.1.4 0l4.9-2.8c.1-.1.2-.2.2-.4s-.1-.3-.2-.4L7.7 6.7z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});