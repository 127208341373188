define("ember-inputmask/components/ssn-input", ["exports", "@ember/runloop", "@ember/object", "ember-inputmask/components/input-mask"], function (_exports, _runloop, _object, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * `{{ssn-input}}` component
   *  Displays an input that masks SSN format
   *
   * @class SSNInput
   */
  var _default = _inputMask.default.extend({
    mask: '999-99-9999',
    oldComponent: '{{ssn-input}}',
    newComponent: '{{one-way-ssn-mask}}',

    updateMask() {
      this.set('mask', '999-99-9999');

      this._super();
    },

    _maskShouldChange: (0, _object.observer)('mask', function () {
      (0, _runloop.once)(this, 'updateMask');
    })
  });

  _exports.default = _default;
});