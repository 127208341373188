define("ember-keyboard/mixins/ember-keyboard", ["exports", "@ember/service", "@ember/object/evented", "@ember/object/mixin", "@ember/object"], function (_exports, _service, _evented, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_evented.default, {
    keyboardPriority: 0,
    keyboard: (0, _service.inject)(),

    init() {
      (0, _object.get)(this, 'keyboard').register(this);
      return this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _object.get)(this, 'keyboard').unregister(this);
    },

    willDestroy() {
      this._super(...arguments);

      (0, _object.get)(this, 'keyboard').unregister(this);
    }

  });

  _exports.default = _default;
});