// Documentation: https://github.com/mixonic/ember-cli-deprecation-workflow

self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember.built-in-components.import' },
    { handler: 'silence', matchId: 'ember.built-in-components.reopen' },
    {
      handler: 'silence',
      matchId: 'ember-simple-auth.initializer.setup-session-restoration',
    },
    {
      handler: 'silence',
      matchId: 'ember-simple-auth.mixins.application-route-mixin',
    },
    {
      handler: 'silence',
      matchId: 'deprecated-run-loop-and-computed-dot-access',
    },
    { handler: 'silence', matchId: 'ember-simple-auth.events.session-service' },
    {
      handler: 'silence',
      matchId: 'ember-simple-auth.mixins.authenticated-route-mixin',
    },
    { handler: 'silence', matchId: 'implicit-injections' },
    {
      handler: 'silence',
      matchId: 'ember-simple-auth.mixins.data-adapter-mixin',
    },
    { handler: 'silence', matchId: 'ember-utils.try-invoke' },
    { handler: 'silence', matchId: 'computed-property.override' },
    { handler: 'silence', matchId: 'ember-component.is-visible' },
    {
      handler: 'silence',
      matchId: 'ember.built-in-components.legacy-arguments',
    },
    { handler: 'silence', matchId: 'this-property-fallback' },
    { handler: 'silence', matchId: 'ember-component.send-action' },
    { handler: 'silence', matchId: 'routing.transition-methods' },
    { handler: 'silence', matchId: 'ember-test-selectors.auto-binding' },
    { handler: 'silence', matchId: 'ember-modifier.use-modify' },
    { handler: 'silence', matchId: 'ember-modifier.no-args-property' },
    { handler: 'silence', matchId: 'ember-modifier.no-element-property' },
    { handler: 'silence', matchId: 'template-compiler.registerPlugin' },
    { handler: 'silence', matchId: 'binding-style-attributes' },
  ],
};
