define("ember-svg-jar/inlined/mod_inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.97 2.5l-.105.574L2.5 11.94V17.5h15v-5.56l-1.362-8.866-.109-.574H3.971zm1.173 1.362h9.714l1.152 7.5h-4.647v.683c0 .762-.6 1.362-1.362 1.362a1.35 1.35 0 01-1.362-1.362v-.683H3.99l1.152-7.5zm-1.28 8.866h3.535c.308 1.166 1.34 2.044 2.6 2.044 1.259 0 2.29-.878 2.598-2.044h3.536v3.41H3.862v-3.41z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    }
  };
  _exports.default = _default;
});