define("ember-svg-jar/inlined/mod_file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke:#000;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Work-Office-Companies__x2F__Office__x2F__office-business-card\"><g id=\"Group_111\"><g id=\"office-business-card\"><path id=\"Rectangle-path_20\" class=\"st0\" d=\"M1 5.8c0-.4.4-.8.8-.8h16.4c.4 0 .8.4.8.8v11.7c0 .4-.4.8-.8.8H1.8c-.4 0-.8-.3-.8-.8V5.8z\"/><path id=\"Shape_778\" class=\"st0\" d=\"M3.3 6.6v3.5c0 .6.5 1.2 1.2 1.2.6 0 1.2-.5 1.2-1.2V4.2c0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6V5\"/><path id=\"Shape_779\" class=\"st0\" d=\"M8 8.9h8.6\"/><path id=\"Shape_780\" class=\"st0\" d=\"M7.3 12.1h9.4\"/><path id=\"Shape_781\" class=\"st0\" d=\"M3.3 15.2h13.3\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});