define("ember-short-number/services/short-number", ["exports", "@ember/application", "@ember/object", "@ember/polyfills", "@ember/service", "ember-short-number/-private/hydrate", "cldr-compact-number"], function (_exports, _application, _object, _polyfills, _service, _hydrate, _cldrCompactNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    __localeData__: null,
    __config__: null,

    /**
     * Percentage from upper limit to consider using upper limit rules
     * e.g. 950,000 is within 95% of upper limit of 1,000,000.  Thus use 1M abbreviation
     * rules
     *
     * @property threshold
     * @default 0.05
     */
    threshold: 0.05,

    init() {
      this._super(...arguments);

      let userConfig = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      let addonConfig = userConfig['ember-short-number'];

      if (addonConfig) {
        (0, _object.set)(this, '__config__', addonConfig);

        if (addonConfig['threshold']) {
          (0, _object.set)(this, 'threshold', addonConfig['threshold']);
        }
      }

      this.__localeData__ = {};
      (0, _hydrate.default)(this, (0, _application.getOwner)(this));
    },

    /**
     * @method addLocaleData
     * @param {Object} data
     */
    addLocaleData(data) {
      this.__localeData__[data.locale.toLowerCase()] = data;
    },

    /**
     * digitsConfig accepts 3 possiblearguments
     *  - significantDigits
     *  - minimumFractionDigits
     *  - maximumFractionDigits
     *
     * extracts one rule for a given language:
     * e.g.
     *  rule = [
     *    [1000, {one: ["0K", 1], other: ["0K", 1]}],
     *    [10000, {one: ["00K", 2], other: ["00K", 2]}]
     *  ]
     *
     * @method format
     * @param {String|Number} value
     * @param {String} [locale='en'] this is the language code as specified by ISO 639-1
     * @param {Object} digitsConfig
     * @return {String}
     */
    format(value) {
      let locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'en';
      let digitsConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const options = (0, _polyfills.assign)({}, {
        threshold: this.threshold
      }, digitsConfig);
      return (0, _cldrCompactNumber.default)(value, locale, this.__localeData__, options);
    }

  });

  _exports.default = _default;
});