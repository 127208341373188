define("ember-keyboard/utils/generate-code-map", ["exports", "@ember/polyfills", "ember-keyboard/fixtures/code-maps/default", "ember-keyboard/fixtures/code-maps/mac-safari-and-chrome", "ember-keyboard/fixtures/code-maps/gecko", "ember-keyboard/fixtures/code-maps/gecko/linux", "ember-keyboard/fixtures/code-maps/gecko/mac", "ember-keyboard/fixtures/code-maps/chromium/linux"], function (_exports, _polyfills, _default, _macSafariAndChrome, _gecko, _linux, _mac, _linux2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateCodeMap;

  function generateCodeMap() {
    let platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    const isGecko = product.indexOf('Gecko') > -1;
    const isChromium = product.indexOf('Chromium') > -1;
    const isChrome = product.indexOf('Chrome') > -1;
    const isSafari = product.indexOf('Safari') > -1;
    const isLinux = platform.indexOf('Linux') > -1;
    const isMac = platform.indexOf('Mac') > -1;
    const codeMap = (0, _polyfills.assign)({}, _default.default);

    if (isGecko) {
      (0, _polyfills.assign)(codeMap, _gecko.default);

      if (isLinux) {
        (0, _polyfills.assign)(codeMap, _linux.default);
      } else if (isMac) {
        (0, _polyfills.assign)(codeMap, _mac.default);
      }
    } else if (isChromium && isLinux) {
      (0, _polyfills.assign)(codeMap, _linux2.default);
    } else if (isMac && (isSafari || isChrome)) {
      (0, _polyfills.assign)(codeMap, _macSafariAndChrome.default);
    }

    return codeMap;
  }
});