define("ember-google-maps/components/g-map/control", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@glimmer/tracking", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _mapComponent, _tracking, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.container}}
    {{#in-element this.container}}
      {{!-- Compatibility: @classNames should be deprecated --}}
      <div id={{this.id}} class={{@classNames}} ...attributes {{g-map/did-insert this.getControl}}>{{yield}}</div>
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "EYzjQxaD",
    "block": "[[[41,[30,0,[\"container\"]],[[[40,[[[1,\"    \"],[11,0],[16,1,[30,0,[\"id\"]]],[16,0,[30,1]],[17,2],[4,[38,3],[[30,0,[\"getControl\"]]],null],[12],[18,3,null],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,2],[[30,0,[\"container\"]]],null]]],[]],null]],[\"@classNames\",\"&attrs\",\"&default\"],false,[\"if\",\"in-element\",\"-in-el-null\",\"g-map/did-insert\",\"yield\"]]",
    "moduleName": "ember-google-maps/components/g-map/control.hbs",
    "isStrictMode": false
  });

  let Control = (_class = class Control extends _mapComponent.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "id", `ember-google-maps-control-${(0, _internals.guidFor)(this)}`);

      _initializerDefineProperty(this, "container", _descriptor, this);

      _defineProperty(this, "lastControlPosition", null);
    }

    get name() {
      return 'controls';
    }

    setup(options) {
      // TODO: Support an existing control position
      let position = google.maps.ControlPosition[options.position];
      this.map.controls[position].push(this.controlElement); // Could use {{prop}} for this (from ember-prop-modifier)

      this.controlElement.index = options.index;
      this.lastControlPosition = position;
      return this.controlElement;
    }

    teardown() {
      let controls = this.map.controls[this.lastControlPosition];
      let index = controls.indexOf(this.controlElement);
      controls.removeAt(index);
    }

    getControl(element) {
      this.controlElement = element;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      var _window, _window$document;

      return (_window = window) === null || _window === void 0 ? void 0 : (_window$document = _window.document) === null || _window$document === void 0 ? void 0 : _window$document.createDocumentFragment();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getControl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getControl"), _class.prototype)), _class);
  _exports.default = Control;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Control);
});