define("ember-short-number/helpers/short-number", ["exports", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    shortNumber: (0, _service.inject)(),

    compute(_ref, digitsConfig) {
      let [number, lang] = _ref;
      return (0, _object.get)(this, 'shortNumber').format(number, lang, digitsConfig);
    }

  });

  _exports.default = _default;
});