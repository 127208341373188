define("ember-inputmask/components/one-way-input-mask", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-inputmask/utils/compare-objects", "inputmask", "@ember/polyfills"], function (_exports, _component, _object, _runloop, _compareObjects, _inputmask, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_NON_BOUND_PROPS = void 0;
  const DEFAULT_OPTIONS = {
    rightAlign: false
  };
  const DEFAULT_NON_BOUND_PROPS = ['keyEvents', 'class', 'classNames', 'positionalParamValue', 'update', 'mask', 'options'];
  /**
   * Displays an input with the specified mask applied to it
   * using Inputmask library. Follows Data-down actions up pattern
   *
   * @class OneWayInputMask
   */

  _exports.DEFAULT_NON_BOUND_PROPS = DEFAULT_NON_BOUND_PROPS;

  const OneWayInputMask = _component.default.extend({
    tagName: 'input',

    /**
     * Set the `_value` to be whatever the `element.value` is
     * @field attributeBindings
     */
    attributeBindings: ['type', '_value:value'],
    // This is where we blacklist all the attributes that should not be bound
    NON_ATTRIBUTE_BOUND_PROPS: DEFAULT_NON_BOUND_PROPS,
    type: 'text',

    /**
     * The mask from [Inputmask.js](https://github.com/RobinHerbots/Inputmask#default-masking-definitions)
     *
     * @argument mask
     * @type String
     */
    mask: '',
    _oldMask: '',

    /**
     * Options accepted by [Inputmask.js](https://github.com/RobinHerbots/Inputmask#options)
     *
     * @argument options
     * @type Object
     */
    options: null,
    _options: null,
    // Internal options so external attribute doesnt clobber it
    _oldOptions: null,
    keyEvents: null,

    /**
     * The value to show inside the input. Can be first `positionalParam`
     *
     * @argument value
     * @type String
     */
    value: null,

    /**
     * Setup _value to be a positional param or the passed param if that is not defined
     *
     * @computed _value
     * @private
     */
    _value: (0, _object.computed)('positionalParamValue', 'value', {
      get() {
        let value = (0, _object.get)(this, 'positionalParamValue');

        if (value === undefined) {
          value = (0, _object.get)(this, 'value');
        }

        return value;
      }

    }),

    init() {
      this._super(...arguments);

      (0, _object.set)(this, 'keyEvents', {
        '13': 'onenter',
        '27': 'onescape'
      }); // Give the mask some default options that can be overridden

      let options = (0, _object.get)(this, 'options');
      (0, _object.set)(this, '_options', (0, _polyfills.assign)({}, DEFAULT_OPTIONS, options)); // We want any attribute that is not explicitally blacklisted to be bound that way we don't
      // have to whitelist every single html attribute that an `input` can have. Borrowed from
      // https://github.com/DockYard/ember-one-way-controls/blob/master/addon/-private/dynamic-attribute-bindings.js

      let newAttributeBindings = [];

      for (let key in this.attrs) {
        if ((0, _object.get)(this, 'NON_ATTRIBUTE_BOUND_PROPS').indexOf(key) === -1 && (0, _object.get)(this, 'attributeBindings').indexOf(key) === -1) {
          newAttributeBindings.push(key);
        }
      }

      (0, _object.set)(this, 'attributeBindings', this.attributeBindings.concat(newAttributeBindings));
    },

    didInsertElement() {
      this._setupMask(); // We're setting this flag because we want to ensure that fastboot doesn't break when using
      // this component. In `didReceiveAttrs` we can potentially change the mask which reqires
      // `this.element` to exist. Fastboot doesn't work with `this.element` so we should only attempt
      // to do this if we know this hook has been called


      (0, _object.set)(this, '_didInsertElement', true);
    },

    didReceiveAttrs() {
      let mask = (0, _object.get)(this, 'mask');
      let oldMask = (0, _object.get)(this, '_oldMask');
      let didMaskChange = mask !== oldMask;
      let options = (0, _object.get)(this, 'options');
      let oldOptions = (0, _object.get)(this, '_oldOptions');
      let didOptionsChange = (0, _compareObjects.areDifferent)(options, oldOptions);

      if (didOptionsChange) {
        // Override external options on top of internal options
        (0, _object.set)(this, '_options', (0, _polyfills.assign)({}, (0, _object.get)(this, '_options'), options));
      } // We want to reapply the mask if it has changed


      if (didMaskChange || didOptionsChange) {
        (0, _object.set)(this, '_oldMask', mask);
        (0, _object.set)(this, '_oldOptions', options);

        this._changeMask();
      }
    },

    willDestroyElement() {
      this._destroyMask();
    },

    /**
     * This action will be called when the value changes and will be passed the unmasked value
     * and the masked value
     *
     * @argument update
     * @type function
     */
    update() {},

    /**
     * _changeEventListener - A place to store the event listener we setup to listen to the 'input'
     * events, because the Inputmask library events don't play nice with the Ember components event
     *
     * @method _changeEventListener
     * @private
     */
    _changeEventListener() {},

    /**
     * keyUp - If the keycode matches one of the keycodes in the `keyEvents` hash we want to fire
     * the passed in action that matches it
     *
     * @method keyUp
     */
    keyUp(event) {
      let method = (0, _object.get)(this, `keyEvents.${event.keyCode}`);

      if (method && (0, _object.get)(this, method)) {
        (0, _object.get)(this, method)(event.target.value);
      }
    },

    /**
     * sendUpdate - Send the update action with the values. Components that inherit from this may
     * need to override this if they want to pass additional data on the update
     *
     * @method sendUpdate
     */
    sendUpdate(unmaskedValue, value) {
      (0, _object.get)(this, 'update')(unmaskedValue, value);
    },

    /**
     * _syncValue - If this component's consumer modifies the passed in `value` inside their `update`
     * method we want to make sure that value is reflected in the input's display.
     *
     * @method _syncValue
     * @private
     */
    _syncValue() {
      let actualValue = (0, _object.get)(this, '_value');
      let renderedValue = this.element.value;

      if (actualValue !== renderedValue) {
        this.element.inputmask.setValue(actualValue);
      }
    },

    /**
     * _processNewValue - Handle when a new value changes
     *
     * @method _processNewValue
     * @private
     * @param {string} value - The masked value visible in the element
     */
    _processNewValue(value) {
      let cursorStart = this.element.selectionStart;
      let cursorEnd = this.element.selectionEnd;

      let unmaskedValue = this._getUnmaskedValue();

      let oldUnmaskedValue = (0, _object.get)(this, '_value');
      let options = (0, _object.get)(this, '_options'); // We only want to make changes if something is different so we don't cause infinite loops or
      // double renders.
      // We want to make sure that that values we compare are going to come out the same through
      // the masking algorithm, to ensure that we only call `update` if the values are actually different
      // (e.g. '1234.' will be masked as '1234' and so when `update` is called and passed back
      // into the component the decimal will be removed, we don't want this)

      if (_inputmask.default.format(String(oldUnmaskedValue), options) !== _inputmask.default.format(unmaskedValue, options)) {
        this.sendUpdate(unmaskedValue, value); // When the value is updated, and then sent back down the cursor moves to the end of the field.
        // We therefore need to put it back to where the user was typing so they don't get janked around

        (0, _runloop.schedule)('afterRender', () => {
          this._syncValue();

          this.element.setSelectionRange(cursorStart, cursorEnd);
        });
      }
    },

    /**
     * _setupMask - Connect the 3rd party input masking library to the element
     *
     * @method _setupMask
     * @private
     */
    _setupMask() {
      let mask = (0, _object.get)(this, 'mask'),
          options = (0, _object.get)(this, '_options');
      let inputmask = new _inputmask.default(mask, options);
      inputmask.mask(this.element); // We need to setup a manual event listener for the change event instead of using the Ember
      // Component event methods, because the Inputmask events don't play nice with the Component
      // ones. Similar issue happens in React.js as well
      // https://github.com/RobinHerbots/Inputmask/issues/1377

      let eventListener = event => this._processNewValue(event.target.value);

      (0, _object.set)(this, '_changeEventListener', eventListener);
      this.element.addEventListener('input', eventListener);
    },

    /**
     * _getUnmaskedValue - Get the value of the element without the mask
     *
     * @method _getUnmaskedValue
     * @private
     * @return {string}  The unmasked value
     */
    _getUnmaskedValue() {
      return this.element.inputmask.unmaskedvalue();
    },

    /**
     * _changeMask - Destroy and reapply the mask when the mask or options change so the mask and
     * options can be dynamic
     *
     * @method _changeMask
     * @private
     */
    _changeMask() {
      if ((0, _object.get)(this, '_didInsertElement') && this.element && this.element.inputmask) {
        this._destroyMask();

        this._setupMask();
      }
    },

    _destroyMask() {
      this.element.removeEventListener('input', this._changeEventListener);
      this.element.inputmask.remove();
    }

  });

  OneWayInputMask.reopenClass({
    positionalParams: ['positionalParamValue']
  });
  var _default = OneWayInputMask;
  _exports.default = _default;
});