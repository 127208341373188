define("@sandydoo/tracked-maps-and-sets/-private/util", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.consumeCollection = void 0;
  _exports.consumeKey = consumeKey;
  _exports.consumeTag = void 0;
  _exports.createTag = createTag;
  _exports.dirtyCollection = void 0;
  _exports.dirtyKey = dirtyKey;
  _exports.dirtyTag = void 0;

  var __decorate = void 0 && (void 0).__decorate || function (decorators, target, key, desc) {
    var c = arguments.length,
        r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
        d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
  };

  class Tag {
    static consumeTag(tag) {
      // read the tag value
      tag.__tag_value__;
    }

    static dirtyTag(tag) {
      // write the tag value
      tag.__tag_value__ = undefined;
    }

  }

  __decorate([_tracking.tracked], Tag.prototype, "__tag_value__", void 0);

  function createTag() {
    return new Tag();
  }

  const consumeTag = Tag.consumeTag;
  _exports.consumeTag = consumeTag;
  const dirtyTag = Tag.dirtyTag; ////////////

  _exports.dirtyTag = dirtyTag;
  const COLLECTION_SYMBOL = {};

  let consumeCollection = obj => {
    consumeKey(obj, COLLECTION_SYMBOL);
  };

  _exports.consumeCollection = consumeCollection;

  let dirtyCollection = obj => {
    dirtyKey(obj, COLLECTION_SYMBOL);
  };

  _exports.dirtyCollection = dirtyCollection;

  if (!true) {
    // eslint-disable-next-line ember/new-module-imports
    _exports.consumeCollection = consumeCollection = obj => Ember.get(obj, '[]'); // eslint-disable-next-line ember/new-module-imports


    _exports.dirtyCollection = dirtyCollection = obj => Ember.notifyPropertyChange(obj, '[]');
  } ////////////


  const OBJECT_TAGS = new WeakMap();

  function getOrCreateTag(obj, key) {
    let tags = OBJECT_TAGS.get(obj);

    if (tags === undefined) {
      tags = new Map();
      OBJECT_TAGS.set(obj, tags);
    }

    let tag = tags.get(key);

    if (tag === undefined) {
      tag = new Tag();
      tags.set(key, tag);
    }

    return tag;
  }

  function consumeKey(obj, key) {
    consumeTag(getOrCreateTag(obj, key));
  }

  function dirtyKey(obj, key) {
    dirtyTag(getOrCreateTag(obj, key));
  }
});