define("ember-svg-jar/inlined/mod_contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke:#000;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Users__x2F__Geometric-Close-Up-Single-User-Neutral__x2F__single-neutral-id-card-3\"><g id=\"Group_312\"><g id=\"Light_312\"><path id=\"Oval_508\" class=\"st0\" d=\"M6.6 14.6c1.3 0 2.3-1 2.3-2.3S7.9 10 6.6 10c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3z\"/><path id=\"Shape_1245\" class=\"st0\" d=\"M10.3 18.7c-.2-1.9-1.8-3.3-3.7-3.3-1.9 0-3.5 1.4-3.7 3.3\"/><path id=\"Shape_1246\" class=\"st0\" d=\"M.5 8h19\"/><path id=\"Shape_1247\" class=\"st0\" d=\"M12.1 4.7h6.6c.5 0 .8.4.8.8v12.4c0 .5-.4.8-.8.8H1.3c-.5 0-.8-.4-.8-.8V5.5c0-.5.4-.8.8-.8h6.6\"/><path id=\"Shape_1248\" class=\"st0\" d=\"M10 3c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2.1-.2.2-.2\"/><path id=\"Shape_1249\" class=\"st0\" d=\"M12.5 12.1h5\"/><path id=\"Shape_1250\" class=\"st0\" d=\"M12.5 14.6h2.9\"/><path id=\"Shape_1251\" class=\"st0\" d=\"M12.1 5.9c0 .2-.2.4-.4.4H8.3c-.2 0-.4-.2-.4-.4V3.4c0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1v2.5z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});