define("ember-filestack/helpers/filestack-url", ["exports", "@ember/component/helper", "@ember/service", "@ember/utils", "@ember/runloop", "@ember/debug"], function (_exports, _helper, _service, _utils, _runloop, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    filestack: (0, _service.inject)(),

    compute(_ref, transformations) {
      let [handleOrUrl] = _ref;

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      if ((0, _utils.isBlank)(handleOrUrl)) {
        return;
      }

      if (!this.filestack.client) {
        this.filestack.initClient().then(() => {
          // workaround bug https://github.com/emberjs/ember.js/issues/14774
          (0, _runloop.join)(() => this.recompute());
        });
        return;
      }

      try {
        return this.filestack.getUrl(handleOrUrl, transformations);
      } catch (e) {
        (true && (0, _debug.warn)(`An error occurred while trying to generate a filestack url for the handle '${handleOrUrl}'. Is this a valid filestack handle or url? Error: ${e}`, {
          id: 'ember-filestack.filestack-url-generation'
        }));
        return '';
      }
    }

  });

  _exports.default = _default;
});