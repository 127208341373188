define("ember-froala-editor/helpers/froala-arg", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.froalaArg = froalaArg;

  function froalaArg(callback) {
    for (var _len = arguments.length, partial = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      partial[_key - 1] = arguments[_key];
    }

    (true && !(typeof callback === 'function') && (0, _debug.assert)('{{froala-arg}} helper requires a function as the first parameter', typeof callback === 'function'));

    let wrapper = function froalaArgClosure() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return callback(this, ...partial, ...args);
    };

    (true && !(wrapper.toString() !== callback.toString()) && (0, _debug.assert)('{{froala-arg}} helper should not be call twice on the same callback', wrapper.toString() !== callback.toString()));
    return wrapper;
  }

  var _default = (0, _helper.helper)(_ref => {
    let [callback, ...partial] = _ref;
    return froalaArg(callback, ...partial);
  });

  _exports.default = _default;
});