define("ember-google-maps/components/g-map/map-component", ["exports", "@ember/application", "@ember/component", "@glimmer/tracking", "@ember/debug", "ember-google-maps/component-managers/map-component-manager", "ember-google-maps/utils/options-and-events"], function (_exports, _application, _component, _tracking, _debug, _mapComponentManager, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapComponentAPI = MapComponentAPI;
  _exports.combine = combine;
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function combine(base, extra) {
    return Object.defineProperties(base, Object.getOwnPropertyDescriptors(extra));
  }

  function MapComponentAPI(source) {
    let name = source.name ?? 'unknown';
    return {
      get map() {
        return source.map;
      },

      get [name]() {
        return source.mapComponent;
      },

      get mapComponent() {
        return source.mapComponent;
      }

    };
  }

  let MapComponent = (_class = class MapComponent {
    get publicAPI() {
      return MapComponentAPI(this);
    }

    get map() {
      var _this$context;

      return (_this$context = this.context) === null || _this$context === void 0 ? void 0 : _this$context.map;
    }

    constructor(owner, args, options, events) {
      _initializerDefineProperty(this, "mapComponent", _descriptor, this);

      _defineProperty(this, "boundEvents", []);

      (0, _application.setOwner)(this, owner);
      this.args = args;
      this.options = options;
      this.events = events;
      this.register();
    }

    setup() {}

    teardown(mapComponent) {
      var _this$onTeardown;

      this.boundEvents.forEach(_ref => {
        let {
          remove
        } = _ref;
        return remove();
      }); // Cleanup events by removing map.

      if (mapComponent) {
        var _mapComponent$setMap;

        (_mapComponent$setMap = mapComponent.setMap) === null || _mapComponent$setMap === void 0 ? void 0 : _mapComponent$setMap.call(mapComponent, null);
      } // Unregister from the parent component


      (_this$onTeardown = this.onTeardown) === null || _this$onTeardown === void 0 ? void 0 : _this$onTeardown.call(this);
    }

    register() {
      if (typeof this.args.getContext === 'function') {
        let {
          context,
          remove
        } = this.args.getContext(this.publicAPI, this.name);
        this.context = context;
        this.onTeardown = remove;
      }
    }
    /* Events */


    addEventsToMapComponent(mapComponent) {
      let events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      (true && !(mapComponent) && (0, _debug.assert)('You need to pass in a map component', mapComponent));
      let boundEvents = (0, _optionsAndEvents.addEventListeners)(mapComponent, events, payload);
      this.boundEvents.concat(boundEvents);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mapComponent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MapComponent;
  (0, _component.setComponentManager)(owner => new _mapComponentManager.MapComponentManager(owner), MapComponent);
});