define("ember-google-maps/components/g-map/autocomplete", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@ember/object", "@ember/object/internals", "@ember/debug"], function (_exports, _component, _templateFactory, _mapComponent, _object, _internals, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    {{yield (hash setup=this.getInput)}}
  {{else}}
    <input id={{this.id}} ...attributes {{g-map/did-insert this.getInput}} />
  {{/if}}
  */
  {
    "id": "XdTx0rLK",
    "block": "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,[[28,[37,3],null,[[\"setup\"],[[30,0,[\"getInput\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"input\"],[16,1,[30,0,[\"id\"]]],[17,1],[4,[38,4],[[30,0,[\"getInput\"]]],null],[12],[13],[1,\"\\n\"]],[]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"g-map/did-insert\"]]",
    "moduleName": "ember-google-maps/components/g-map/autocomplete.hbs",
    "isStrictMode": false
  });

  let Autocomplete = (_class = class Autocomplete extends _mapComponent.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "id", `ember-google-maps-autocomplete-${(0, _internals.guidFor)(this)}`);
    }

    get name() {
      return 'autocompletes';
    }

    setup(options, events) {
      (true && !(this.inputElement) && (0, _debug.assert)(`
ember-google-maps: No input found for autocomplete.

When using the block form of the autocomplete component, make sure to call the “setup” method on your input to let autocomplete know about it:

<map.autocomplete as |autocomplete|>
  <input {{did-insert autocomplete.setup}} />
</map.autocomplete>

Did you mean to use the block form? You can also do the following:

<map.autocomplete id="my-custom-id" class="my-custom-class" />
      `, this.inputElement));
      let autocomplete = new google.maps.places.Autocomplete(this.inputElement, options);
      this.addEventsToMapComponent(autocomplete, events, this.publicAPI); // Compatibility: Register the custom `onSearch` event.

      this.addEventsToMapComponent(autocomplete, {
        onPlaceChanged: this.args.onSearch
      }, this.publicAPI);
      return autocomplete;
    }

    update(mapComponent) {
      var _mapComponent$setOpti;

      mapComponent === null || mapComponent === void 0 ? void 0 : (_mapComponent$setOpti = mapComponent.setOptions) === null || _mapComponent$setOpti === void 0 ? void 0 : _mapComponent$setOpti.call(mapComponent, this.newOptions);
      return mapComponent;
    }

    getInput(input) {
      this.inputElement = input;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "getInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getInput"), _class.prototype)), _class);
  _exports.default = Autocomplete;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Autocomplete);
});