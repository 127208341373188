define("ember-froala-editor/helpers/merged-hash", ["exports", "@ember/component/helper", "@ember/polyfills"], function (_exports, _helper, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mergedHash = mergedHash;

  function mergedHash() {
    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    // Assumes all params are objects
    return (0, _polyfills.assign)({}, ...objects);
  }

  var _default = (0, _helper.helper)((params, hash) => mergedHash(...params, hash));

  _exports.default = _default;
});