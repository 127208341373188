define("ember-short-number/-private/to-locale-fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(value, locale, digitsConfig) {
    if (value && typeof value === 'number') {
      return value.toLocaleString(locale, digitsConfig);
    }
  }
});