define("ember-inputmask/components/phone-number-input", ["exports", "@ember/runloop", "@ember/object", "ember-inputmask/components/input-mask"], function (_exports, _runloop, _object, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * `{{phone-number-input}}` component.
   *
   * Displays an input that masks a US phone number. Country code
   * not included because this is specifically US formatting.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * OPTIONS:
   *   extensions - bool
   *     Allows optional extensions to be added to numbers
   *
   * @class PhoneNumberMask
   */
  var _default = _inputMask.default.extend({
    mask: '(299) 999-9999',
    oldComponent: '{{phone-number-input}}',
    newComponent: '{{one-way-phone-mask}}',
    updateMask: function () {
      if (this.get('extensions')) {
        this.set('mask', '(299) 999-9999[ x 9{1,4}]');
      }

      this._super();
    },
    _maskShouldChange: (0, _object.observer)('mask', 'extensions', function () {
      (0, _runloop.once)(this, 'updateMask');
    })
  });

  _exports.default = _default;
});