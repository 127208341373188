define("ember-svg-jar/inlined/mod_link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.3 12.7l7-7c.4-.4 1-.6 1.6-.5.6.2 1 .6 1.2 1.2.2.6 0 1.2-.5 1.6l-9.5 9.5c-1.3 1.3-3.4 1.3-4.7 0s-1.3-3.4 0-4.7L11.5 3c1.8-1.8 4.7-1.8 6.4 0 1.8 1.8 1.8 4.6 0 6.4l-7 7\" fill=\"none\" stroke=\"#000\" stroke-width=\".8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});