define("ember-drag-sort/utils/trigger", ["exports", "@ember/debug", "@ember/test-helpers"], function (_exports, _debug, _testHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trigger;
  _exports.move = move;
  _exports.sort = sort;

  // // https://github.com/jgwhite/ember-sortable/blob/21d2c513f96796f9b1a56983d34cf501a1f169c2/tests/integration/components/sortable-group-test.js#L35-L40
  // export function triggerEvent (elementOrSelector, eventName, params) {
  //   run(() => {
  //     const element =
  //       (elementOrSelector instanceof Element)
  //         ? elementOrSelector
  //         : find(elementOrSelector)
  //     if (typeof params === 'function') params = params(element)
  //     const event = new CustomEvent(eventName, params)
  //     element.dispatchEvent(event)
  //   })
  // }
  function trigger(elementOrSelector, eventName, isDraggingUp) {
    const element = elementOrSelector instanceof Element ? elementOrSelector : find(elementOrSelector);
    const params = {};

    if (isDraggingUp != null) {
      const modifier = isDraggingUp ? 0.25 : 0.75;
      const inner = element.offsetHeight * modifier;
      const outer = element.getBoundingClientRect().top;
      params.clientY = inner + outer;
    }

    return (0, _testHelpers.triggerEvent)(elementOrSelector, eventName, params);
  }

  async function sort(sourceList, sourceIndex, targetIndex, above, handleSelector) {
    let sourceItem = sourceList.children[sourceIndex];
    (true && !(sourceItem) && (0, _debug.assert)(`source item not exist at index ${sourceIndex}`, sourceItem));
    if (handleSelector) sourceItem = sourceItem.querySelector(handleSelector);
    (true && !(!handleSelector || sourceItem) && (0, _debug.assert)('handle does not exist', !handleSelector || sourceItem));
    const targetItem = sourceList.children[targetIndex];
    (true && !(targetItem) && (0, _debug.assert)(`target item not exist at index ${targetIndex}`, targetItem));
    await trigger(sourceItem, 'dragstart');
    await trigger(targetItem, 'dragover', above);
    await trigger(sourceItem, 'dragend');
    return (0, _testHelpers.settled)();
  }

  async function move(sourceList, sourceIndex, targetList, targetIndex, above, handleSelector) {
    let sourceItem = sourceList.children[sourceIndex];
    (true && !(sourceItem) && (0, _debug.assert)(`source item not exist at index ${sourceIndex}`, sourceItem));
    if (handleSelector) sourceItem = sourceItem.querySelector(handleSelector);
    (true && !(!handleSelector || sourceItem) && (0, _debug.assert)('handle does not exist', !handleSelector || sourceItem));
    const targetListLength = targetList.childElementCount;

    if (targetListLength) {
      if (targetIndex == null) {
        targetIndex = targetListLength - 1;
        above = false;
      }

      const targetItem = targetList.children[targetIndex];
      (true && !(targetItem) && (0, _debug.assert)(`target item not exist at index ${targetIndex}`, targetItem));
      await trigger(sourceItem, 'dragstart');
      await trigger(targetList, 'dragenter');
      await trigger(targetItem, 'dragover', above);
      await trigger(sourceItem, 'dragend');
    } else {
      (true && !(!targetIndex) && (0, _debug.assert)(`target list is empty, the only available target index is 0, but target index ${targetIndex} was provided`, !targetIndex));
      await trigger(sourceItem, 'dragstart');
      await trigger(targetList, 'dragenter');
      await trigger(sourceItem, 'dragend');
    }

    return (0, _testHelpers.settled)();
  }
});