define("ember-filestack/components/filestack-picker/component", ["exports", "@ember/component", "@ember/object", "@ember/polyfills", "@ember/service"], function (_exports, _component, _object, _polyfills, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    filestack: (0, _service.inject)(),
    // All the possible picker options taken from https://filestack.github.io/filestack-js/interfaces/pickeroptions.html
    pickerOptions: Object.freeze(['accept', 'allowManualRetry', 'cleanupImageExif', 'concurrency', 'container', 'customSourceContainer', 'customSourceName', 'customSourcePath', 'customText', 'disableStorageKey', 'disableThumbnails', 'disableTransformer', 'displayMode', 'dropPane', 'exposeOriginalFile', 'fromSources', 'globalDropZone', 'hideModalWhenUploading', 'imageDim', 'imageMax', 'imageMin', 'lang', 'maxFiles', 'maxSize', 'minFiles', 'modalSize', 'onCancel', 'onClose', 'onFileSelected', 'onFileUploadFailed', 'onFileUploadFinished', 'onFileUploadProgress', 'onFileUploadStarted', 'onOpen', 'onUploadDone', 'onUploadStarted', 'preferLinkOverStore', 'rootId', 'startUploadingWhenMaxFilesReached', 'storeTo', 'transformations', 'uploadConfig', 'uploadInBackground', 'videoResolution']),
    // gathers all the options to initialize picker
    gatheredOptions: (0, _object.computed)('pickerOptions.[]', function () {
      let options = {};
      this.pickerOptions.forEach(o => {
        let value = this.get(o);

        if (value !== undefined) {
          options[o] = value;
        }
      });
      return options;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.initPicker();
    },

    didUpdateAttrs() {
      if (this.picker) {
        this.picker.close();
      }

      this.initPicker();
    },

    async initPicker() {
      if (typeof FastBoot !== 'undefined') {
        return;
      }

      try {
        await this.filestack.initClient();
        let options = this.options || {};
        let gatheredOptions = this.gatheredOptions;
        let pickerOptions = (0, _polyfills.assign)({}, options, gatheredOptions);
        let picker = await this.filestack.getPicker(pickerOptions);
        await picker.open();
        this.picker = picker;
      } catch (e) {
        if (this.onError) {
          this.onError(e);
        } else {
          throw e;
        }
      }
    }

  });

  _exports.default = _default;
});