define("ember-keyboard/mixins/activate-keyboard-on-insert", ["exports", "@ember/object/mixin", "@ember/object/evented", "@ember/object"], function (_exports, _mixin, _evented, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    activateKeyboardWhenPresent: (0, _evented.on)('didInsertElement', function () {
      (0, _object.set)(this, 'keyboardActivated', true);
    })
  });

  _exports.default = _default;
});