define("ember-parachute/-private/state", ["exports", "@ember/debug", "@ember/utils", "ember-parachute/query-params"], function (_exports, _debug, _utils, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParamsStateFor;

  /**
   * Creates QueryParamsState interface.
   *
   * @param {Ember.NativeArray} queryParamsArray
   * @param {Ember.Controller} controller
   * @returns {object}
   */
  function queryParamsState(queryParamsArray, controller) {
    return queryParamsArray.reduce((state, qp) => {
      let value = qp.value(controller);
      state[qp.key] = {
        value,
        serializedValue: qp.serializedValue(controller),
        as: qp.as,
        defaultValue: qp.defaultValue,
        changed: JSON.stringify(value) !== JSON.stringify(qp.defaultValue)
      };
      return state;
    }, {}, undefined);
  }
  /**
   * Creates new instance of QueryParamsState for a given controller.
   *
   * @export
   * @public
   * @param {Ember.Controller} controller
   * @returns {object}
   */


  function queryParamsStateFor(controller) {
    (true && !((0, _utils.isPresent)(controller)) && (0, _debug.assert)('[ember-parachute] Cannot construct query params state object without a controller', (0, _utils.isPresent)(controller)));

    let {
      queryParamsArray
    } = _queryParams.default.metaFor(controller);

    return queryParamsState(queryParamsArray, controller);
  }
});