define("ember-froala-editor/helpers/to-string", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toString = toString;

  function toString(context) {
    for (var _len = arguments.length, partial = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      partial[_key - 1] = arguments[_key];
    }

    // @update={{to-string (fn (mut this.content))}}
    if (typeof context === 'function') {
      return function toStringClosure(content) {
        let contentString = content && typeof content.toString === 'function' ? content.toString() : content;

        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        return context(contentString, ...partial, ...args);
      }; // {{to-string this.content}}
    } else {
      return context && typeof context.toString === 'function' ? context.toString() : context;
    }
  }

  var _default = (0, _helper.helper)(_ref => {
    let [context, ...partial] = _ref;
    return toString(context, ...partial);
  });

  _exports.default = _default;
});