define("ember-svg-jar/inlined/mod_phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.7 5.7c.7-.7.7-1.9 0-2.7l-2-2C5.4.7 4.9.5 4.4.5s-1 .2-1.3.5L2 2.2C1 3.1.9 4.6 1.6 5.7c3.2 4.8 7.4 9 12.2 12.2 1.1.7 2.6.6 3.5-.4l1.1-1.1c.7-.7.7-1.9 0-2.7l-2-2c-.7-.7-1.9-.7-2.7 0l-.7.7c-2.2-1.8-4.3-3.9-6.1-6.1l.8-.6z\" fill=\"none\" stroke=\"#000\" stroke-width=\".8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});