define("ember-froala-editor/components/froala-content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="fr-view">
    {{@content}}
    {{yield}}
  </div>
  */
  {
    "id": "mZcnx3O1",
    "block": "[[[11,0],[17,1],[24,0,\"fr-view\"],[12],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@content\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-froala-editor/components/froala-content.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});