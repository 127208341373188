define("ember-svg-jar/inlined/icons8-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 3a11.967 11.967 0 00-7.793 2.875 1 1 0 101.299 1.52A9.948 9.948 0 0115 5a9.986 9.986 0 019.951 9H22l4 6 4-6h-3.05C26.437 7.852 21.277 3 15 3zM4 10l-4 6h3.05C3.563 22.148 8.723 27 15 27c2.969 0 5.697-1.083 7.793-2.875a1 1 0 10-1.299-1.52A9.948 9.948 0 0115 25a9.986 9.986 0 01-9.951-9H8l-4-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30",
      "width": "30",
      "height": "30"
    }
  };
  _exports.default = _default;
});