define("ember-svg-jar/inlined/mod_menuchildren", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke:#000;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Interface-Essential__x2F__Menu__x2F__navigation-menu-2\"><g id=\"Group_22_1_\"><g id=\"navigation-menu-2\"><path id=\"Shape_81\" class=\"st0\" d=\"M19.5 5.6c0 .4-.4.8-.8.8H6.3c-.5 0-.8-.4-.8-.8v-.8c0-.5.3-.8.8-.8h12.4c.5 0 .8.4.8.8v.8z\"/><path id=\"Shape_82\" class=\"st0\" d=\"M17 10.4c0 .4-.4.8-.8.8H3.8c-.5 0-.8-.4-.8-.8v-.8c0-.4.4-.8.8-.8h12.4c.5 0 .8.4.8.8v.8z\"/><path id=\"Shape_83\" class=\"st0\" d=\"M14.5 15.2c0 .4-.4.8-.8.8H1.3c-.5 0-.8-.4-.8-.8v-.8c0-.4.4-.8.8-.8h12.4c.5 0 .8.4.8.8v.8z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});