define("ember-parachute/query-params", ["exports", "@ember/object/mixin", "@ember/polyfills", "@ember/debug", "@ember/utils", "@ember/object", "ember-parachute/-private/symbols", "ember-parachute/-private/parachute-meta", "ember-parachute/-private/state"], function (_exports, _mixin, _polyfills, _debug, _utils, _object, _symbols, _parachuteMeta, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  /**
   * Query Params class.
   *
   * @export
   * @class QueryParams
   */

  class QueryParams {
    /**
     * @method constructor
     * @constructor
     * @public
     * @returns {QueryParams}
     */
    constructor() {
      let queryParams = (0, _polyfills.assign)({}, ...arguments); // Cleanup the queryParams object. Some keys can be passed
      // as undefined via extend to nullify a QP

      queryParams = keys(queryParams).reduce((qps, key) => {
        if ((0, _utils.isPresent)(queryParams[key])) {
          qps[key] = queryParams[key];
        }

        return qps;
      }, {});
      this.queryParams = queryParams;
      this.Mixin = this._generateMixin();
    }
    /**
     * Extend this QueryParams instance with the passed query paramaters
     *
     * @method extend
     * @public
     * @returns {QueryParams}
     */


    extend() {
      return new QueryParams(this.queryParams, ...arguments);
    }
    /**
     * Get the meta object for the given controller
     *
     * @method metaFor
     * @public
     * @static
     * @param  {Ember.Controller} controller
     * @returns {ParachuteMeta}
     */


    static metaFor(controller) {
      (true && !(this.hasParachute(controller)) && (0, _debug.assert)(`[ember-parachute] The controller '${controller}' is not set up with ember-parachute.`, this.hasParachute(controller)));
      return (0, _object.get)(controller, _symbols.PARACHUTE_META);
    }
    /**
     * Check if the given controller has ember-parachute mixed in.
     *
     * @method _hasParachute
     * @public
     * @static
     * @param  {Ember.Controller} controller
     * @returns {boolean}
     */


    static hasParachute(controller) {
      return controller && (0, _object.get)(controller, _symbols.HAS_PARACHUTE);
    }
    /**
     * Returns a query param based on a urlKey.
     *
     * @method lookupQueryParam
     * @public
     * @static
     * @param {Ember.Controller} controller
     * @param {string} urlKey
     * @returns {Object}
     *
     * @memberof QueryParams
     */


    static lookupQueryParam(controller, urlKey) {
      let {
        queryParamsArray
      } = this.metaFor(controller);
      return queryParamsArray.findBy('as', urlKey);
    }
    /**
     * Generate a `key`:`value` pair object for all the query params
     *
     * @method queryParamsFor
     * @public
     * @static
     * @param  {Ember.Controller} controller
     * @returns {object}
     */


    static queryParamsFor(controller) {
      let {
        queryParamsArray
      } = this.metaFor(controller);
      return queryParamsArray.reduce((qps, qp) => {
        qps[qp.key] = qp.value(controller);
        return qps;
      }, {}, undefined);
    }
    /**
     * Generate an object with the current state of each query param
     *
     * @method stateFor
     * @public
     * @static
     * @param  {Ember.Controller} controller
     * @returns {object}
     */


    static stateFor(controller) {
      return (0, _state.default)(controller);
    }
    /**
     * Reset all or given params to their default value
     *
     * @method resetParamsFor
     * @public
     * @static
     * @param  {Ember.Controller} controller
     * @param  {string[]} params Array of QPs to reset. If empty, all QPs will be reset.
     */


    static resetParamsFor(controller) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let {
        queryParamsArray
      } = this.metaFor(controller);
      let defaults = queryParamsArray.reduce((defaults, qp) => {
        if ((0, _utils.isEmpty)(params) || params.indexOf(qp.key) > -1) {
          defaults[qp.key] = qp.defaultValue;
        }

        return defaults;
      }, {}, undefined);
      (0, _object.setProperties)(controller, defaults);
    }
    /**
     * Set the default value for a given param.
     *
     * @method setDefaultValue
     * @public
     * @static
     * @param  {Ember.Controller} controller
     * @param  {string} param
     * @param  {any} defaultValue
     * @returns {void}
     */


    static setDefaultValue(controller, param, defaultValue) {
      let {
        queryParams
      } = this.metaFor(controller);
      (true && !(queryParams[param]) && (0, _debug.assert)(`[ember-parachute] The query parameter '${param}' does not exist.`, queryParams[param]));
      (0, _object.set)(queryParams[param], 'defaultValue', defaultValue);
    }
    /**
     * Generate the meta object for this instance's QPs
     *
     * @method generateMeta
     * @private
     * @returns {ParachuteMeta}
     */


    _generateMeta() {
      return new _parachuteMeta.default(this.queryParams);
    }
    /**
     * Generate a Mixin from this instance's queryParams
     *
     * @method _generateMixin
     * @private
     * @returns {Ember.Mixin}
     */


    _generateMixin() {
      let {
        queryParams,
        defaultValues,
        qpMapForController
      } = this._generateMeta();

      let ControllerMixin = _mixin.default.create(defaultValues, {
        /**
         * @private
         * @property {boolean}
         */
        [_symbols.HAS_PARACHUTE]: true,

        /**
         * Meta must be generated on the instance so it doesnt bleed in tests
         *
         * @private
         * @property {Ember.ComputedProperty}
         */
        [_symbols.PARACHUTE_META]: (0, _object.computed)(() => this._generateMeta()).readOnly(),

        /**
         * Create the `key` to `name` mapping used by Ember to register the QPs
         *
         * @public
         * @property {object}
         */
        queryParams: qpMapForController,

        /**
         * Create a CP that is a collection of all QPs and their value
         *
         * @public
         * @property {Ember.ComputedProperty}
         */
        allQueryParams: (0, _object.computed)(...keys(queryParams), function () {
          return QueryParams.queryParamsFor(this);
        }).readOnly(),

        /**
         * Create a CP that holds the state of each QP.
         *
         * @public
         * @property {Ember.ComputedProperty}
         */
        queryParamsState: (0, _object.computed)(...keys(queryParams), `${_symbols.PARACHUTE_META}.queryParamsArray.@each.defaultValue`, function () {
          return QueryParams.stateFor(this);
        }).readOnly(),

        /**
         * Reset query params to their default value. Accepts an optional array
         * of query param keys to reset.
         *
         * @public
         * @param {string[]} [params=[]]
         * @returns {void}
         */
        resetQueryParams() {
          let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          QueryParams.resetParamsFor(this, params);
        },

        /**
         * Update default value for a given query param.
         *
         * @param {string} key
         * @param {any} defaultValue
         * @returns {void}
         */
        setDefaultQueryParamValue(key, defaultValue) {
          QueryParams.setDefaultValue(this, key, defaultValue);
        }

      });

      return ControllerMixin;
    }

  }

  _exports.default = QueryParams;
});