define("ember-svg-jar/inlined/mod_recentmessages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 9v8a2.4 2.4 0 01-2.4 2.4H3.4A2.4 2.4 0 011 17V5.8a2.4 2.4 0 012.4-2.4h8m8 .8a3.2 3.2 0 11-6.4 0 3.2 3.2 0 016.4 0z\" stroke=\"#1C182B\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});