define("ember-svg-jar/inlined/mod_map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M17.582 44.584a1 1 0 01-1.164 0l-13-9.284A1 1 0 013 34.486V4.944a1 1 0 011.582-.814L17 13l13.418-9.584a1 1 0 011.164 0l13 9.284a1 1 0 01.418.814v29.542a1 1 0 01-1.582.814L31 35zM17 13v31.772M31 35V3.228\" stroke-width=\"2\"/></g>",
    "attrs": {
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});