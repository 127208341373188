define("ember-inputmask/components/one-way-date-mask", ["exports", "ember-inputmask/components/one-way-input-mask", "@ember/object", "@ember/polyfills"], function (_exports, _oneWayInputMask, _object, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPTIONS = {
    inputFormat: 'dd/mm/yyyy',
    outputFormat: 'ddmmyyyy'
  };
  /**
   * @class OneWayDateMask
   */

  var _default = _oneWayInputMask.default.extend({
    /**
     * @field mask
     * @override
     */
    mask: 'datetime',

    init() {
      this._super(...arguments);

      (0, _object.set)(this, '_options', (0, _polyfills.assign)({}, (0, _object.get)(this, '_options'), DEFAULT_OPTIONS));
    }

  });

  _exports.default = _default;
});