define("ember-keyboard/utils/listener-name", ["exports", "ember-keyboard/utils/get-cmd-key"], function (_exports, _getCmdKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = listenerName;

  function sortedKeys(keyArray) {
    return keyArray.sort().join('+');
  }

  function listenerName(type) {
    let keyArray = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (keyArray.indexOf('cmd') > -1) {
      keyArray[keyArray.indexOf('cmd')] = (0, _getCmdKey.default)();
    }

    const keys = keyArray.length === 0 ? '_all' : sortedKeys(keyArray);
    return `${type}:${keys}`;
  }
});