define("ember-google-maps/utils/options-and-events", ["exports", "@ember/string", "@ember/runloop", "ember-google-maps/utils/platform"], function (_exports, _string, _runloop, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OptionsAndEvents = void 0;
  _exports.addEventListener = addEventListener;
  _exports.addEventListeners = addEventListeners;
  _exports.ignoredOptions = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ignoredOptions = ['lat', 'lng', 'getContext', 'classNames', 'onLoad'];
  _exports.ignoredOptions = ignoredOptions;
  const IGNORED = Symbol('Ignored'),
        EVENT = Symbol('Event'),
        OPTION = Symbol('Option'),
        OPTIONS = Symbol('Options'),
        EVENTS = Symbol('Events');

  function isEvent(name) {
    return name.startsWith('on');
  }

  function isOnceEvent(name) {
    return name.startsWith('onceOn');
  }

  class OptionsAndEvents {
    constructor(args) {
      _defineProperty(this, "whosThatProp", new Map());

      _defineProperty(this, OPTION, new Set());

      _defineProperty(this, EVENT, new Set());

      this.args = args;
      this.ignoredSet = new Set(ignoredOptions); // Sort and cache the arguments by type.

      this.parse();
      let getFromArgs = this.getFromArgs.bind(this);

      if (_platform.HAS_NATIVE_PROXY) {
        let target = Object.create(null);
        let optionsHandler = new ArgsProxyHandler(this[OPTION], getFromArgs);
        let eventsHandler = new ArgsProxyHandler(this[EVENT], getFromArgs);
        this.options = new Proxy(target, optionsHandler);
        this.events = new Proxy(target, eventsHandler);
      } else {
        this.options = newNoProxyFallback(this[OPTION], getFromArgs);
        this.events = newNoProxyFallback(this[EVENT], getFromArgs);
      }
    }

    getFromArgs(prop) {
      let identity = this.whosThatProp.get(prop);

      switch (identity) {
        case OPTIONS:
          return this.args.options[prop];

        case EVENTS:
          return this.args.events[prop];

        case OPTION:
        case EVENT:
          return this.args[prop];
      }
    }

    parse() {
      for (let prop in this.args) {
        let identity = this.identify(prop);

        switch (identity) {
          case OPTION:
          case EVENT:
            this[identity].add(prop);
            this.whosThatProp.set(prop, identity);
            break;

          case OPTIONS:
            for (let innerProp in this.args[prop]) {
              this[OPTION].add(innerProp);
              this.whosThatProp.set(innerProp, identity);
            }

            break;

          case EVENTS:
            for (let innerProp in this.args[prop]) {
              this[EVENT].add(innerProp);
              this.whosThatProp.set(innerProp, identity);
            }

            break;

          case IGNORED:
            break;
        }
      }
    }

    identify(prop) {
      if (this.isIgnored(prop)) {
        return IGNORED;
      }

      if (prop === 'options') {
        return OPTIONS;
      }

      if (prop === 'events') {
        return EVENTS;
      }

      if (this.isEvent(prop)) {
        return EVENT;
      }

      return OPTION;
    }

    isEvent(prop) {
      return isOnceEvent(prop) || isEvent(prop);
    }

    isIgnored(prop) {
      return this.ignoredSet.has(prop);
    }

  }

  _exports.OptionsAndEvents = OptionsAndEvents;

  class ArgsProxyHandler {
    constructor(cache, getFromArgs) {
      this.cache = cache;
      this.getFromArgs = getFromArgs;
      this.setCache = new Map();
    }

    get(_target, prop) {
      return this.setCache.get(prop) ?? this.getFromArgs(prop);
    }

    set(_target, prop, value) {
      if (value !== undefined) {
        this.setCache.set(prop, value);
      } else {
        this.setCache.delete(prop);
      } // Never fail to set a value


      return true;
    }

    has(_target, prop) {
      return this.setCache.has(prop) || this.cache.has(prop);
    }

    ownKeys() {
      return Array.from(new Set([...this.setCache.keys(), ...this.cache.values()]));
    }

    isExtensible() {
      return false;
    }

    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true
      };
    }

  }

  function newNoProxyFallback(propSet, getFromArgs) {
    let obj = {};
    propSet.forEach(prop => {
      Object.defineProperty(obj, prop, {
        enumerable: true,
        configurable: true,

        get() {
          return obj[prop] ?? getFromArgs(prop);
        },

        set(prop, value) {
          if (value === undefined) {
            delete obj[prop];
          } else {
            obj[prop] = value;
          }
        }

      });
    });
    return obj;
  }
  /* Events */


  function addEventListener(target, originalEventName, action) {
    let payload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    let isDom = target instanceof Element;
    let isOnce = isOnceEvent(originalEventName);
    let maybeDom = isDom ? 'Dom' : '';
    let maybeOnce = isOnce ? 'Once' : '';
    let listenerType = `add${maybeDom}Listener${maybeOnce}`;
    let addGoogleListener = google.maps.event[listenerType];
    let eventName = isOnce ? originalEventName.slice(6) // onceOn
    : originalEventName.slice(2); // on

    eventName = (0, _string.decamelize)(eventName);

    function callback(googleEvent) {
      let params = _objectSpread({
        event: window.event,
        googleEvent,
        eventName,
        target
      }, payload);

      (0, _runloop.next)(target, action, params);
    }

    let listener = addGoogleListener(target, eventName, callback);
    return {
      name: eventName,
      listener,
      remove: () => listener.remove()
    };
  }
  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */


  function addEventListeners(target, events) {
    let payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Object.entries(events).map(_ref => {
      let [originalEventName, action] = _ref;
      return addEventListener(target, originalEventName, action, payload);
    });
  }
});