define("@sandydoo/tracked-maps-and-sets/-private/set", ["exports", "@sandydoo/tracked-maps-and-sets/-private/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackedWeakSet = _exports.TrackedSet = void 0;

  class TrackedSet extends Set {
    // **** KEY GETTERS ****
    has(value) {
      (0, _util.consumeKey)(this, value);
      return super.has(value);
    } // **** ALL GETTERS ****


    entries() {
      (0, _util.consumeCollection)(this);
      return super.entries();
    }

    keys() {
      (0, _util.consumeCollection)(this);
      return super.keys();
    }

    values() {
      (0, _util.consumeCollection)(this);
      return super.values();
    }

    forEach(fn) {
      (0, _util.consumeCollection)(this);
      super.forEach(fn);
    }

    get size() {
      (0, _util.consumeCollection)(this);
      return super.size;
    } // **** KEY SETTERS ****


    add(value) {
      (0, _util.dirtyKey)(this, value);
      (0, _util.dirtyCollection)(this);
      return super.add(value);
    }

    delete(value) {
      (0, _util.dirtyKey)(this, value);
      (0, _util.dirtyCollection)(this);
      return super.delete(value);
    } // **** ALL SETTERS ****


    clear() {
      super.forEach((_v, k) => (0, _util.dirtyKey)(this, k));
      (0, _util.dirtyCollection)(this);
      return super.clear();
    }

  }

  _exports.TrackedSet = TrackedSet;

  if (typeof Symbol !== undefined) {
    let originalIterator = TrackedSet.prototype[Symbol.iterator];
    Object.defineProperty(TrackedSet.prototype, Symbol.iterator, {
      get() {
        (0, _util.consumeCollection)(this);
        return originalIterator;
      }

    });
  }

  class TrackedWeakSet extends WeakSet {
    has(value) {
      (0, _util.consumeKey)(this, value);
      return super.has(value);
    }

    add(value) {
      (0, _util.dirtyKey)(this, value);
      return super.add(value);
    }

    delete(value) {
      (0, _util.dirtyKey)(this, value);
      return super.delete(value);
    }

  }

  _exports.TrackedWeakSet = TrackedWeakSet;
});