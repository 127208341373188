define("semantic-ui-ember/components/ui-shape", ["exports", "ember", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-shape"], function (_exports, _ember, _base, _uiShape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend(_base.default, {
    layout: _uiShape.default,
    module: 'shape',
    classNames: ['ui', 'shape']
  });

  _exports.default = _default;
});