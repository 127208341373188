define("semantic-ui-ember/templates/components/ui-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99dAAA4w",
    "block": "[[[10,\"input\"],[15,3,[36,1]],[15,\"tabindex\",[36,2]],[15,\"checked\",[28,[37,3],[[33,4]],null]],[15,\"disabled\",[28,[37,3],[[33,5]],null]],[15,4,[36,0]],[12],[13],[1,\"\\n\"],[10,\"label\"],[12],[1,[34,6]],[13],[1,\"\\n\"],[18,1,[[28,[37,8],[[30,0],\"execute\"],null]]]],[\"&default\"],false,[\"type\",\"name\",\"tabindex\",\"unbound\",\"checked\",\"disabled\",\"label\",\"yield\",\"action\"]]",
    "moduleName": "semantic-ui-ember/templates/components/ui-radio.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});