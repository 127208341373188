define("ember-composable-helpers/helpers/filter", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filter = filter;

  function filter(_ref) {
    let [callback, array] = _ref;

    if ((0, _utils.isEmpty)(callback) || !array) {
      return [];
    }

    return array.filter(callback);
  }

  var _default = (0, _helper.helper)(filter);

  _exports.default = _default;
});