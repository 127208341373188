define("ember-froala-editor/components/froala-editor", ["exports", "@ember/component", "@ember/template-factory", "@ember/application", "@ember/debug", "@ember/object", "@ember/polyfills", "@ember/template", "@glimmer/component", "ember-froala-editor/helpers/froala-arg", "ember-froala-editor/helpers/froala-html", "froala-editor"], function (_exports, _component, _templateFactory, _application, _debug, _object, _polyfills, _template, _component2, _froalaArg, _froalaHtml, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    {{did-insert this.createEditor this.optionsWithInitEvent}}
    {{did-update this.updateEditorContent @content}}
    {{did-update this.updateDisabledState @disabled}}
    {{will-destroy this.destroyEditor}}
  >
    {{#if this.fastboot.isFastboot}}
      {{@content}}
    {{else}}
      {{! template-lint-disable no-unbound }}
      {{unbound @content}}
    {{/if}}
  </div>
  */
  {
    "id": "EvdClmlR",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"createEditor\"]],[30,0,[\"optionsWithInitEvent\"]]],null],[4,[38,1],[[30,0,[\"updateEditorContent\"]],[30,2]],null],[4,[38,1],[[30,0,[\"updateDisabledState\"]],[30,3]],null],[4,[38,2],[[30,0,[\"destroyEditor\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"fastboot\",\"isFastboot\"]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],[[30,2]],null]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@content\",\"@disabled\"],false,[\"did-insert\",\"did-update\",\"will-destroy\",\"if\",\"unbound\"]]",
    "moduleName": "ember-froala-editor/components/froala-editor.hbs",
    "isStrictMode": false
  });

  let FroalaEditorComponent = (_class = class FroalaEditorComponent extends _component2.default {
    get update() {
      return this.args.update;
    }

    get updateEvent() {
      return this.args.updateEvent || 'contentChanged';
    }

    get fastboot() {
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }

    get propertyOptions() {
      let options = {};

      for (let propertyName in this) {
        if (Object.prototype.hasOwnProperty.call(_froalaEditor.default.DEFAULTS, propertyName)) {
          options[propertyName] = this[propertyName];
        }
      }

      return options;
    }

    get argumentOptions() {
      let options = {};

      for (let argumentName in this.args) {
        if (Object.prototype.hasOwnProperty.call(_froalaEditor.default.DEFAULTS, argumentName)) {
          options[argumentName] = this.args[argumentName];
        }
      }

      return options;
    }

    get propertyCallbacks() {
      let callbacks = {}; // Regex's used for replacing things in the name

      const regexOnPrefix = /^on-/g;
      const regexHyphens = /-/g;
      const regexDots = /\./g; // Class methods are not available in a `for (name in this)` loop

      let propertyNames = Object.getOwnPropertyNames(this.__proto__);

      for (let propertyName of propertyNames) {
        // Only names that start with on- are callbacks
        if (propertyName.indexOf('on-') !== 0) {
          continue;
        }

        (true && !(typeof this[propertyName] === 'function') && (0, _debug.assert)(`<FroalaEditor> ${propertyName} event callback property must be a function`, typeof this[propertyName] === 'function')); // Convert the name to what the event name would be

        let eventName = propertyName;
        eventName = eventName.replace(regexOnPrefix, '');
        eventName = eventName.replace(regexHyphens, '.'); // Special use case for the 'popups.hide.[id]' event
        // Ember usage would be '@on-popups-hide-id=(fn)'
        // https://www.froala.com/wysiwyg-editor/docs/events#popups.show.[id]

        if (eventName.indexOf('popups.hide.') === 0) {
          let id = eventName.replace('popups.hide.', '');
          id = id.replace(regexDots, '-'); // Convert back to hyphens

          eventName = `popups.hide.[${id}]`;
        } // Wrap the callback to pass the editor in as the first argument


        callbacks[eventName] = this[propertyName];
      }

      return callbacks;
    }

    get argumentCallbacks() {
      let callbacks = {}; // Regex's used for replacing things in the name

      const regexOnPrefix = /^on-/g;
      const regexHyphens = /-/g;
      const regexDots = /\./g;

      for (let argumentName in this.args) {
        // Only names that start with on- are callbacks
        if (argumentName.indexOf('on-') !== 0) {
          continue;
        }

        (true && !(typeof this.args[argumentName] === 'function') && (0, _debug.assert)(`<FroalaEditor> @${argumentName} event callback argument must be a function`, typeof this.args[argumentName] === 'function')); // Convert the name to what the event name would be

        let eventName = argumentName;
        eventName = eventName.replace(regexOnPrefix, '');
        eventName = eventName.replace(regexHyphens, '.'); // Special use case for the 'popups.hide.[id]' event
        // Ember usage would be '@on-popups-hide-id=(fn)'
        // https://www.froala.com/wysiwyg-editor/docs/events#popups.show.[id]

        if (eventName.indexOf('popups.hide.') === 0) {
          let id = eventName.replace('popups.hide.', '');
          id = id.replace(regexDots, '-'); // Convert back to hyphens

          eventName = `popups.hide.[${id}]`;
        } // Wrap the callback to pass the editor in as the first argument


        callbacks[eventName] = this.args[argumentName];
      }

      return callbacks;
    }

    get combinedOptions() {
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return (0, _polyfills.assign)({}, config['ember-froala-editor'], this.options, this.propertyOptions, this.args.options, this.argumentOptions);
    }

    get combinedCallbacks() {
      return (0, _polyfills.assign)({}, this.propertyCallbacks, this.argumentCallbacks);
    }

    get optionsWithInitEvent() {
      let options = this.combinedOptions; // Determine which event will be called first

      let initEventName = options.initOnClick ? 'initializationDelayed' : 'initialized'; // Ensure the events object exists

      options.events = options.events || {}; // Grab the current init callback before overriding

      let initEventCallback = options.events[initEventName]; // Add the created callback to the proper initialization event

      options.events[initEventName] = (0, _froalaArg.froalaArg)(this.setupEditor, initEventName, initEventCallback);
      return options;
    }

    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "options", {});

      _defineProperty(this, "editor", null);

      (true && !((0, _template.isHTMLSafe)(args.content) || args.content === null || typeof args.content === 'undefined') && (0, _debug.assert)('<FroalaEditor> @content argument must be a SafeString from htmlSafe()', (0, _template.isHTMLSafe)(args.content) || args.content === null || typeof args.content === 'undefined'));
      (true && !(typeof args.update === 'function' || typeof args.update === 'undefined') && (0, _debug.assert)('<FroalaEditor> @update argument must be a function', typeof args.update === 'function' || typeof args.update === 'undefined'));
      (true && !(typeof args.updateEvent === 'string' || typeof args.updateEvent === 'undefined') && (0, _debug.assert)('<FroalaEditor> @updateEvent argument must be a string', typeof args.updateEvent === 'string' || typeof args.updateEvent === 'undefined'));
      (true && !(typeof args.options === 'object' || typeof args.options === 'undefined') && (0, _debug.assert)('<FroalaEditor> @options argument must be an object', typeof args.options === 'object' || typeof args.options === 'undefined'));
    }

    createEditor(element, _ref2) {
      let [options] = _ref2;
      new _froalaEditor.default(element, options);
    }

    setupEditor(editor, initEventName, initEventCallback) {
      // Add a reference to each other so they accessible from either
      editor.component = this;
      this.editor = editor; // Handle the initial @disabled state
      // Note: Run before the event callbacks are added,
      //       so the @on-edit-off callback isn't triggered

      if (this.args.disabled) {
        this.editor.edit.off();
      } // Call the combinedCallbacks getter once


      let callbacks = this.combinedCallbacks; // Add event handler callbacks, passing in the editor as the first arg

      for (let eventName in callbacks) {
        this.editor.events.on(eventName, (0, _froalaArg.froalaArg)(callbacks[eventName]));
      } // Add update callback when a setter is passed in


      if (this.update) {
        this.editor.events.on(this.updateEvent, (0, _froalaHtml.froalaHtml)(this.update), true); // true = run first
      } // Add destroyed callback so the editor can be unreferenced


      this.editor.events.on('destroy', (0, _froalaArg.froalaArg)(this.teardownEditor), false); // false = run last
      // Since we overrode this event callback,
      // call the passed in callback(s) if there are any

      for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }

      if (initEventCallback === 'function') {
        // Mimic default behavior by binding the editor instance to the called context
        initEventCallback.bind(editor)(...args);
      }

      if (typeof callbacks[initEventName] === 'function') {
        // Mimic a typical on-* callback by passing in the editor as the first arg
        callbacks[initEventName](editor, ...args);
      }
    }

    updateEditorContent(element, _ref3) {
      let [content] = _ref3;
      (true && !((0, _template.isHTMLSafe)(content) || content === null || typeof content === 'undefined') && (0, _debug.assert)('<FroalaEditor> updated @content argument must be a SafeString from htmlSafe()', (0, _template.isHTMLSafe)(content) || content === null || typeof content === 'undefined')); // content should be undefined or a SafeString

      let html = (0, _template.isHTMLSafe)(content) ? content.toString() : ''; // When the editor is available,
      // updates should go through `editor.html.set()`

      if (this.editor) {
        // Avoid recursive loop, check for changed content
        if (this.editor.html.get() !== html) {
          this.editor.html.set(html);
        } // When the editor is NOT available,
        // updates should go through the DOM (directly)

      } else {
        // Avoid recursive loop, check for changed content
        if (element.innerHTML !== html) {
          element.innerHTML = html;
        }
      }
    }

    updateDisabledState(element, _ref4) {
      let [disabled] = _ref4;

      // Ignore when the editor is not available
      if (!this.editor) {
        return;
      } // Change the editor to the appropriate state


      if (disabled && !this.editor.edit.isDisabled()) {
        this.editor.edit.off();
      } else if (!disabled && this.editor.edit.isDisabled()) {
        this.editor.edit.on();
      }
    }

    destroyEditor() {
      // Guard against someone calling editor.destroy()
      // from an event callback, which teardownEditor()
      // would still trigger and unreference the editor
      // before this callback had a chance to run
      if (this.editor) {
        this.editor.destroy();
      }
    }

    teardownEditor(editor
    /*, ...args*/
    ) {
      delete editor.component;
      this.editor = null;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "createEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEditorContent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateEditorContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDisabledState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDisabledState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teardownEditor"), _class.prototype)), _class);
  _exports.default = FroalaEditorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FroalaEditorComponent);
});