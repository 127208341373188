define("ember-parachute/utils/lookup-controller", ["exports", "@ember/object", "@ember/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lookupController;

  /**
   * Lookup controller on route.
   *
   * @export
   * @param {Ember.Route} route
   * @param {function(any): any} [ownerLookupFn=getOwner]
   * @returns {Ember.Controller}
   */
  function lookupController(route) {
    let ownerLookupFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _application.getOwner;
    let controller = (0, _object.get)(route, 'controller');

    if (!controller) {
      /**
       * If the controller doesnt exist, use the class proto instead. Before, we
       * would create the controller if it didnt exist which caused a lot of issues
       * (especially with authentication) due to the controller being created
       * prematurely.
       */
      let controllerName = (0, _object.get)(route, 'controllerName') || (0, _object.get)(route, 'routeName');
      let factory = ownerLookupFn(route).factoryFor(`controller:${controllerName}`);
      return factory.class.proto();
    }

    return controller;
  }
});