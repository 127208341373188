define("ember-svg-jar/inlined/mod_image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke:#000;stroke-width:.8;stroke-linecap:round;stroke-linejoin:round}</style><g id=\"Images-Photography__x2F__Pictures__x2F__picture-polaroid-man\"><g id=\"Group_42_1_\"><g id=\"picture-polaroid-man\"><path id=\"Rectangle-path_52\" class=\"st0\" d=\"M.8 2.3c0-.4.3-.8.8-.8h12.2c.4 0 .8.3.8.8v12.2c0 .4-.3.8-.8.8H1.6c-.4 0-.8-.3-.8-.8V2.3z\"/><path id=\"Shape_193\" class=\"st0\" d=\"M.8 12.9h13.7\"/><path id=\"Shape_194\" class=\"st0\" d=\"M3.8 16.7L15 19c.2 0 .4 0 .6-.1.2-.1.3-.3.3-.5l2.4-11.9c.1-.4-.2-.8-.6-.9L16 5.2\"/><path id=\"Shape_195\" class=\"st0\" d=\"M13.4 12.9c-.1-.4-.4-.7-.8-.8l-3.3-1.4V9.1s.7-.3.7-1.9c.5 0 .7-1.5 0-1.5.3-.4.4-1 .3-1.5-.4-1.5-4.1-1.5-4.4 0-1.7-.3-.4 1.3-.4 1.5-.7 0-.7 1.5 0 1.5 0 1.6.7 1.9.7 1.9v1.5L2.9 12c-.4.2-.7.5-.8.8h11.3z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});